/* You can add global styles to this file, and also import other style files */

.ng-invalid:not(form) {
  border-left:5px solid red;
  display: block;
}

.ng-valid:not(form) {
  border-left:5px solid green;
  display: block;
}

.material {
  @import "~ng-snotify/styles/material";
}

.btn-primary {
  background-color: #003056 !important;
  border-color: transparent !important;
}

.btn-primary:hover:not([disabled]) {
  background-color: #001423 !important;
}

.badge-dpsg {
  background-color: #810a1a !important;
  color: white !important;
}
